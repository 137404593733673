import React, { Fragment } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function TopicSelectorMobile(props) {
  return (
    <Accordion
      className='topicSelector'
      id='topicSelectorMobile'
      expanded={props.panel}
      onChange={props.handlePanel}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='wiki-panel-content'
        id='wiki-panel-header'
      >
        <Typography>Choose your topic:</Typography>
      </AccordionSummary>
      <AccordionDetails id='topicSelectorDropdown'>
        <List id='topicSelectorList'>
          {props.itemList.map((e, i) => (
            <Fragment key={e.id}>
              <ListItem button className="topicSelectorItem" onClick={() => props.setTopic(i)}>
                <ListItemText primary={e.name} />
              </ListItem>
              {props.itemList[i + 1] ? <Divider /> : null}
            </Fragment>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
