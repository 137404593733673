import React from 'react';
import './calc.scss';
import { Typography, Slider } from '@material-ui/core';
import { BMI } from '../../utils/calculations/calculations';
import InputWithUnit from './inputs/InputWithUnit';
import { FullWidthInput } from './inputs/FullWidthInput';

export default class BMICalcComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      panel: false,
      color: 'black',
      qCalc: {
        weight: '',
        height: '',
        imperialHeight: '',
        unit: 'metric',
        result: null,
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  getSliderColor = () => {
    if (this.state && this.state.qCalc && this.state.qCalc.result) {
      if (this.state.qCalc.result <= 18.5) {
        return '#2196f3';
      } else if (this.state.qCalc.result <= 25) {
        return '#4caf50';
      } else if (this.state.qCalc.result <= 30) {
        return '#ffeb3b';
      } else {
        return '#f44336';
      }
    } else {
      return 'grey';
    }
  };

  getBMILabel = (key) => {
    if (key) {
      if (key <= 18.5) {
        return 'Underweight';
      } else if (key <= 25) {
        return 'Normal';
      } else if (key <= 30) {
        return 'Overweight';
      } else {
        return 'Obese';
      }
    } else {
      return '';
    }
  };

  handleChange = (event) => {
    const qCalc = {
      ...this.state.qCalc,
      [event.target.name]: event.target.value,
    };
    if (qCalc.unit === 'imperial') {
      qCalc.metricHeight = qCalc.height;
      qCalc.height = qCalc.imperialHeight;
    }
    if (qCalc.weight && qCalc.height && qCalc.unit) {
      qCalc.result = BMI(qCalc);
    } else {
      qCalc.result = null;
    }
    if (qCalc.unit === 'imperial') {
      qCalc.height = qCalc.metricHeight;
      delete qCalc.metricHeight;
    }
    this.setState({
      ...this.state,
      qCalc: qCalc,
    });
  };
  marks = [
    {
      value: 10,
      label: 'Underweight',
    },
    {
      value: 19,
      label: 'Healthy',
    },
    {
      value: 25.5,
      label: 'Overweight',
    },
    {
      value: 30.5,
      label: 'Obese',
    },
  ];
  getSlider(value) {
    return (
      <Slider
        defaultValue={value}
        value={value}
        aria-labelledby="discrete-slider-always"
        step={null}
        marks={this.marks}
        min={10}
        max={40}
        style={{
          color: this.getSliderColor(),
          display: this.state.qCalc.result ? 'block' : 'none',
        }}
        valueLabelDisplay="on"
        disabled
      />
    );
  }

  render() {
    return (
      <>
        <FullWidthInput>
          <InputWithUnit
            onChange={this.handleChange}
            unitType="weight"
            label="Weight"
            name="weight"
            type="number"
            value={this.state.qCalc}
          />
        </FullWidthInput>
        <FullWidthInput>
          <InputWithUnit
            onChange={this.handleChange}
            unitType="height"
            label="Height"
            name="height"
            type="number"
            value={this.state.qCalc}
          />
        </FullWidthInput>

        <div id="result">
          <Typography variant="h6">
            {this.state.qCalc.result
              ? 'Your BMI is:'
              : 'Fill in your details above to see your BMI'}
          </Typography>

          <div
            style={{
              display: this.state.qCalc.result ? 'block' : 'none',
            }}
            className="bmiSlider"
          >
            {this.getSlider(this.state.qCalc.result)}
            <div className="bmiSliderText">
              <h3>According to your BMI, you are: </h3>
              <h2>{this.getBMILabel(this.state.qCalc.result)}</h2>
            </div>
          </div>
        </div>
      </>
    );
  }
}
