import { createSlice } from '@reduxjs/toolkit'

const titleSlice = createSlice({
  name: 'title',
  initialState: "",
  reducers: {
    setTitleName: {
        reducer: (state, action) => {
          return state = action.payload
        },
        prepare: (text) => {
          return { payload: text }
        },
      },
  }
})

export const { setTitleName } = titleSlice.actions

export default titleSlice.reducer