import React from "react";

import Routes from "./routes.jsx";
import { ThemeProvider, createMuiTheme, CssBaseline } from "@material-ui/core";
import Footer from './Components/Footer/Footer';
//responsible for enabling native extentions
import nativeExtentsions from './utils/nativeExtentions'
nativeExtentsions()

// Theme
const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#37CEFF",
      disabled:"#ffffff",
    },
    secondary:{
      main: "#1488FF",
    },
    disabled:{
      main:"grey",
    },
  },
});

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {};
  }

  render() {
    return (
      <div className="App">
          <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Routes />
            <Footer/>
          </ThemeProvider>
      </div>
    );
  }
}

export default App;
