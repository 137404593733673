/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    name: 'Weight Loss',
    id: 'weight-loss',
    content: [
      {
        type: 'title',
        text: 'Introduction',
      },
      {
        type: 'article',
        text: (
          <>
            <b>
              So you wanna lose weight, but you have no idea on where to start?
            </b>{' '}
            Well you have come to the right article. I recommend you read
            everything before starting. But if you already know some things on
            the topic, you can use the table of contents above to jump to a
            specific topic. The concept of weight loss is simple. You follow a
            diet and do exercise in order to lose kg/pounds. But in here you
            will see how it works specifically. There will be a summary at the
            end on the chapter that explains how you can get started.
            <br />{' '}
            <i>Note: This article may be changed and expanded at any time.</i>
          </>
        ),
      },
      {
        type: 'title',
        text: 'Dieting and Nutrition',
      },
      {
        type: 'article',
        text: (
          <>
            There are many strategies to lose weight. Though you may have heard
            of keto, low carb, fasting, or something else. The most used, and in
            our perspective the most effective and healthy one is calorie
            deficit. As the name says, this means having a deficit in the amount
            of calories you consume. The recommended deficit for starters is 400
            calories. The max should be 500 or 550 (extreme cases. Try to stay
            below 500) To apply your deficit and decide your calorie goal, you
            need to calculate your TDEE. TDEE stands for{' '}
            <b>Total Daily Energy Expenditure</b>, and as the name implies, it
            is the total amount of calories you use per day.{' '}
            <i>You can calculate your TDEE below this paragraph.</i> We
            recommend recalculating it every 2-4 weeks.
            <br /> Now for people with a low TDEE, having this deficit may leave
            you on a very low daily goal. We recommend going for atleast 2000
            calories per day. Meaning that if your current TDEE is lower than
            2500, try to use exercise to get it to 2500 or above. The absolute
            minimum a person should consume a day is 1800 calories.
          </>
        ),
      },
      {
        type: 'component',
        text: 'quickTDEECalculator',
      },
      {
        type: 'title',
        text: 'Food & Meal Tracking',
      },
      {
        type: 'article',
        text: (
          <>
            {' '}
            Tracking the calories and nutrition you consume is vital for both
            weight loss and weight gain. Its very common for people to
            underestimate or overestimate what they eat. Tracking your meals can
            make the difference.
            <br />
            <br />
            <b>How to start Tracking</b>
            <br />
            Though it technically isn't required, tracking can be done best by
            one of the popular tracking apps, a few of these are:
            <ul>
              <li>My Fitness Pal</li>
              <li>CronoMeter</li>
            </ul>
            These apps all have their pros and cons compared to the other. So be
            sure to take your time and find your preferred one. <br />
            In these apps, you can see an overview of your day, and you can add
            a meal to a specific part. For Example, you can add oatmeal and milk
            to your breakfast. Adding a meal can be done by either simply
            scanning your product's barcode, or searching it in the search bar.
            Make sure to verify your portion and also verify if the calories and
            macros are correct in the app (By comparing it to the package).
          </>
        ),
      },
      {
        type: 'title',
        text: 'Working Out',
      },
      {
        type: 'article',
        text: (
          <>
            You might be aware of what strength training and hypertrophy is. If
            not, you can read about it later in the strength and hypertrophy
            articles. Besides endurance, these are the 2 main ways of training.
            But what is the most effective way for weight loss? The truth is,
            there isn't. While there are some exceptions, weight training during
            weight loss is just to increase your calorie output. So be sure to
            calculate your activities in your nutrition plan (add burned
            calories to your TDEE).
            <br />
            <br />
            <b>The exceptions</b>
            <br /> The exceptions really are nothing special. If you just
            started lifting, you will experience what we call "noobie gains".
            This means that since your muscles just started getting that
            activity, you will gain muscle very quickly and easily. The other
            exception is when your fat percentage is over a certain level. This
            will make you gain some muscle while in calorie deficit. Our advice.
            Ignore both of these exceptions and focus on your goal. Though you
            might have some benefit thanks to it, don't focus too much on it and
            try to keep looking out for normal workout schedule and diet plan.
          </>
        ),
      },
      {
        type: 'title',
        text: 'How to Start & Summary',
      },
      {
        type: 'article',
        text: (
          <>
            So, you have been caught up with the basics and are ready to make
            your first steps into weight loss! Here i will repeat the steps you
            have to take to start. Again, if you are new i highly recommend and
            nearly require you to read the text above before you start. It is a
            5 min read and worth it. Take some pen and paper or open a doc on
            your pc and lets get working.
            <ul>
              <li>
                Calculate your TDEE on our calculator page, or using the quick
                calculator above. if you workout, you can change your activity
                level to how much. Or let it stay on sedentary and calculate it
                yourself
              </li>
              <li>
                Take your favorite weight loss app and fill in your TDEE minus
                400-500 as your goal. Make sure to check your macro goal as
                well.
              </li>
              <li>
                Every meal or snack you take, scan the barcode or look it up,
                and add it in the app. Make sure to verify the portion you take
                (grams, ml, or whatever unit applies)
              </li>
              <li>
                make sure to workout, and keep your calorie goal above 2000 (not
                TDEE. That should be above 2500)
              </li>
            </ul>
            <i>
              Join our Discord if you got any questions about this or need
              clarification. Or think this article misses information / need
              improvement
            </i>
          </>
        ),
      },
    ],
  },
  {
    name: 'Weight Gain',
    id: 'weight-gain',
    content: [
      {
        type: 'title',
        text: 'Introduction',
      },
      {
        type: 'article',
        text: (
          <>
            <b>
              Gaining weight, or Bulking, is the process of increasing muscle
              mass by eating more than you consume and weight lifting.
            </b>{' '}
            In this article, we will go over the process of bulking and what
            steps to undertake. The main things we look over is dieting and
            workouts. Before you start off and make plans, read the entire
            article.
            <br />{' '}
            <i>Note: This article may be changed and expanded at any time.</i>
          </>
        ),
      },
      {
        type: 'title',
        text: 'Should i bulk?',
      },
      {
        type: 'article',
        text: (
          <>
            <i>Should you bulk?</i> Before you decide you want to bulk, you
            should know a few things:
            <ul>
              <li>
                <b>You're fat percentage is around 15%</b>
                <br /> Especially if you want to lose weight, its smarter to go
                to a lower fat percentage first. Dont try to "bulk and lose
                weight" at the same time. This will <i>not</i> work the way you
                think. No matter what other sources may tell you.
              </li>
              <li>
                <b>Medical conditions</b>
                <br /> If you have any medical conditions that may influence
                your experience, please consult a doctor or professional before
                start dieting and working out.
              </li>
            </ul>
          </>
        ),
      },
      {
        type: 'title',
        text: 'Dieting',
      },
      {
        type: 'article',
        text: (
          <>
            For gaining muscle mass, you need to consume more calories than yo
            expand. At Team Bicep we recommend around 300 calories will do the
            trick. Our advised maximum is 500 calories. Going over this amount
            is hardly necessary. <bn />
            So to calculate how much you need to consume, calculate your TDEE
            and add 300 calories to it. Make sure you select the correct
            activity level. You can calculate your TDEE below
          </>
        ),
      },
      {
        type: 'component',
        text: 'quickTDEECalculator',
      },
      {
        type: 'title',
        text: 'Working out',
      },
      {
        type: 'article',
        text: (
          <>
            Working out is essential to a bulk. Its the difference between
            gaining muscle and gaining fat. For specifics on gaining either mass
            or strength, please check the respective article on that topic.
            Right now we will discuss the basics. <br />
            To gain muscle while eating in a surplus, you need to lift weights.
            Because what you specifically need is{' '}
            <b>
              <i>Progressive Overload.</i>
            </b>
            <br />
            Progressive overload means you are using your muscles for something
            heavy. You lift something above the level it is used to. This could
            go from something as simple from carrying the heavy bag of groceries
            from you car to your house, to benching twice your bodyweight. never
            the less, you will need to perform progressive overload to make
            progress. This is why sports, or other physical activities are/may
            be less effective. <br />
            <br />
            <b>So how to do it? </b> To put it simply: Lift heavy. Not too heavy
            that you can barely lift it. But you should feel like you are
            lifting a heavy object, and probably cant do more than lift it 12
            times in a row (AKA 12 reps)
          </>
        ),
      },
    ],
  },
  {
    name: 'Overview',
    id: 'overview',
    content: [
      {
        type: 'title',
        text: 'TDEE',
      },
      {
        type: 'article',
        text: (
          <>
            TDEE is a form of measurement to find out how much calories you
            consume per day. TDEE stands for{' '}
            <b>Total Daily Energy Expenditure</b>. Tdee is used to give you an
            idea on how much you should be eating. If you work out, do sports,
            or any other physical activities, your TDEE will be higher than
            most.
            <br />
            In most cases, you calculate your TDEE to estimate your diets goal.
            Which usually is either weight loss, weight gain, or maintenance.
            <ul>
              <li>
                <b>Weight loss:</b>
                <br />
                For weight loss, you generally want to remove 500 calories from
                your TDEE. Unless your TDEE is below 2300. Try to increase your
                activity level if its below moderate. Otherwise you could go
                below the 1800 goal.{' '}
                <b>
                  <i>Never, ever, go below 1500 calories a day</i>
                </b>
              </li>
              <li>
                <b>Weight gain:</b>
                <br />
                For gaining weight/muscle mass, you should add 300 to 500
                calories to your TDEE. Starting off with 300 is recommended, so
                you decrease the chances of gaining fat instead of muscle
              </li>
              <li>
                <b>Maintenance:</b>
                <br />
                Maintenance is fairly simple. just eat your TDEE. Though its
                important you calculate your TDEE well. 
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
];
