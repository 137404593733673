import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import Home from "./pages/Home/Home";
import Wiki from "./pages/Wiki/Wiki";
import Banner from './Components/Banner/Banner';
import Calculators from "./pages/Calculators/Calculators";

export default class Routes extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Banner />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/calculators" component={Calculators} />
            <Route exact path="/calculators/:type" component={Calculators} />
            <Route exact path="/wiki" component={Wiki} />
            <Route exact path="/wiki/:topic" component={Wiki} />
            <Route exact path="/wiki/:topic/:section" component={Wiki} />
            <Redirect from="*" to="/" />
          </Switch>
        </Router>
      </div>
    );
  }
}
