import React from 'react';
import {
  TextField,
  MenuItem,
  FormControl,
} from '@material-ui/core';

export default class InputRegular extends React.Component {
  constructor(props) {
    super(props);
    ({
      onChange: this.onChange,
      unitType: this.unitType,
      value: this.value,
      ...this.rest
    } = this.props);
  }

  render() {
    return (
      <FormControl className="fullWidthInput">
        {!this.props.select ? (
          <TextField
            className="measurementInputField"
            variant="filled"
            value={this.props.value[this.unitType]}
            onChange={this.onChange}
            {...this.rest}
            name={this.props.name}
          />
        ) : (
          <TextField
            variant="filled"
            labelId={`${this.unitType}-label`}
            value={this.props.value[this.unitType]}
            onChange={this.onChange}
            {...this.rest}
            name={this.props.name}
            select
          >
            {this.props.select.map((v) => (
              <MenuItem key={v.key} value={v.key}>
                {v.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </FormControl>
    );
  }
}
