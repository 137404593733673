import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './DetailButtonComponent.scss';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Divider,
} from '@material-ui/core';

class DetailsButton extends React.Component {
  render() {
    return (
      <Button className="buttonRoot">
        <div
          style={{
            width: '100%',
          }}
        >
          <span className="detailsSpanStyle">details</span>
          <ExpandMoreIcon
            className={
              this.props.expanded
                ? 'expandIconStyle expanded'
                : 'expandIconStyle collapsed'
            }
          />
          <Divider className="dividerStyle" />
        </div>
      </Button>
    );
  }
}

export default class DetailsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      panel: false
    };
    this.handlePanel = this.handlePanel.bind(this);
  }
  handlePanel = () => {
    this.setState({ ...this.state, panel: !this.state.panel });
  };
  render() {
    return (
      <Accordion
        expanded={this.state.panel}
        onChange={() => this.handlePanel()}
        className={this.props.className + " hiddenAccordion"}
      >
        <AccordionSummary>
          {' '}
          <DetailsButton expanded={this.state.panel} />
        </AccordionSummary>
        <AccordionDetails>
          {this.props.children}
        </AccordionDetails>
      </Accordion>
    );
  }
}
