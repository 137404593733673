import React from 'react';
import './QuickCalculator.scss';
import {
  Button,
  Typography,
  Paper,
  TextField,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { tdee, getHeightMenuValues } from '../../utils/calculations/calculations';

export default class QuickCalculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qCalc: {
        gender: null,
        age: '',
        metricWeight: '',
        metricHeight: '',
        imperialWeight: '',
        imperialHeight: '',
        activityLevel: 1,
        unit: 'metric',
        result: null,
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    const qCalc = {
      ...this.state.qCalc,
      [event.target.name]: event.target.value,
    };
    if (qCalc.gender && qCalc.age && qCalc.unit) {
      if (qCalc.unit === 'metric' && qCalc.metricWeight && qCalc.metricHeight) {
        qCalc.result = tdee(qCalc);
      } else if (
        qCalc.unit === 'imperial' &&
        qCalc.imperialWeight &&
        qCalc.imperialHeight
      ) {
        qCalc.result = tdee(qCalc);
      } else {
        qCalc.result = null;
      }
    } else {
      qCalc.result = null;
    }
    this.setState({
      ...this.state,
      qCalc: qCalc,
    });
  };

  render() {
    tdee();
    return (
      <Paper id="quickCalc">
        {/* Title */}
        <Typography id="quickCalcTitle" variant="h5">
          Quick TDEE Calculator
        </Typography>
        {/* halve inputs */}
        <div id="calcInputs">
          {/* Gender */}
          <FormControl>
            <TextField
              variant="filled"
              label="Gender"
              id="qCalcGender"
              value={this.state.qCalc.gender}
              name="gender"
              onChange={this.handleChange}
              select
            >
              <MenuItem value={'male'}>Male</MenuItem>
              <MenuItem value={'female'}>Female</MenuItem>
            </TextField>
          </FormControl>
          {/* Age */}
          <FormControl>
            <TextField
              variant="filled"
              aria-label="Age"
              label="Age"
              name="age"
              value={this.state.qCalc.age}
              onChange={this.handleChange}
            />
          </FormControl>
          {/* Weight */}
          <div className="qCalcSelectInputs">
            {/* Weight Input */}
            <FormControl
              className="measurementInput"
              style={{ borderTopRightRadius: 0 }}
            >
              <TextField
                className="measurementInputField"
                variant="filled"
                label="Weight"
                name={this.state.qCalc.unit + 'Weight'}
                value={this.state.qCalc[this.state.qCalc.unit + 'Weight'] || ''}
                onChange={this.handleChange}
                id="qcWeight"
              />
            </FormControl>
            {/* Unit selector */}
            <FormControl className="unit">
              <TextField
                className="unitSelect"
                labelId="qcalc-weight-unit-label"
                variant="filled"
                value={this.state.qCalc.unit}
                label="Unit"
                name="unit"
                onChange={this.handleChange}
                select
              >
                <MenuItem value={'metric'}>Kg</MenuItem>
                <MenuItem value={'imperial'}>Lbs</MenuItem>
              </TextField>
            </FormControl>
          </div>
          {/* Height */}
          <div className="qCalcSelectInputs">
            {/* Height Input toggle */}
            {this.state.qCalc.unit === 'metric' ? (
              //  Height input for metric
              <FormControl className="measurementInput">
                <TextField
                  className="measurementInputField"
                  variant="filled"
                  label="Height"
                  name="metricHeight"
                  value={this.state.qCalc.height}
                  onChange={this.handleChange}
                  id="qcHeight"
                />
              </FormControl>
            ) : (
              // Height input for Imperial
              <FormControl className="measurementInput">
                <TextField
                  className="measurementInputField"
                  labelId="imperial-height-label"
                  variant="filled"
                  label="Height"
                  value={this.state.qCalc.height}
                  name="imperialHeight"
                  onChange={this.handleChange}
                  id="qcHeightImperial"
                  select
                >
                  {getHeightMenuValues().map((menuItems) => (
                    <MenuItem
                      value={`${menuItems.f}|${menuItems.i}`}
                    >{`${menuItems.f}'${menuItems.i}"`}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
            {/* Unit Selector */}
            <FormControl className="unit">
              <TextField
                className="unitSelect"
                labelId="unit-label-b"
                variant="filled"
                value={this.state.qCalc.unit || ''}
                label="Unit"
                name="unit"
                onChange={this.handleChange}
                select
              >
                <MenuItem value={'metric'}>Cm</MenuItem>
                <MenuItem value={'imperial'}>Ft</MenuItem>
              </TextField>
            </FormControl>
          </div>
        </div>
        {/* Activity level */}
        <FormControl id="activityLevel">
          <TextField
            variant="filled"
            labelId="activity-label"
            value={this.state.qCalc.activityLevel || ''}
            label="Activity level"
            name="activityLevel"
            onChange={this.handleChange}
            select
          >
            <MenuItem value={1}>Sedentary</MenuItem>
            <MenuItem value={2}>Lightly active</MenuItem>
            <MenuItem value={3}>Moderately active</MenuItem>
            <MenuItem value={4}>Very active </MenuItem>
            <MenuItem value={5}>Extra active</MenuItem>
          </TextField>
        </FormControl>
        <div id="quickCalcResult">
          <Typography variant="h6">Your TDEE is:</Typography>
          <Typography color="primary" variant="h5">
            {Math.round(this.state.qCalc.result)} Calories
          </Typography>
        </div>
        {/* More Calculators button */}
        <div id="moreCalculatorsButton">
          <Button>More Calculators</Button>
        </div>
      </Paper>
    );
  }
}
