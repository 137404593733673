import React from 'react';
import './Home.scss';
import { connect } from 'react-redux';
import { setTitleName } from '../../actions/setTitle';
import { Card, Container, Typography, CardActionArea } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import wikiIcon from '@material-ui/icons/LibraryBooksOutlined';
import calcIcon from '../../assets/icons/calcIcon.svg';
import discIcon from '../../assets/icons/disc.svg';

import { Link } from 'react-router-dom';
import QuickCalculator from '../../Components/QuickCalculator/QuickCalculator';

class Home extends React.Component {
  componentDidMount() {
    this.props.setTitleName('Home');
  }
  buttonList = [
    {
      name: 'Join our Discord',
      icon: discIcon,
      link: {
        external: true,
        url: 'https://discord.teambicep.com/',
      },
    },
    {
      name: 'Check our Instagram',
      icon: InstagramIcon,
      link: {
        external: true,
        url: 'https://instagram.com/realteambicep',
      },
    },
    {
      name: 'Check the Fitness Wiki',
      icon: wikiIcon,
      link: {
        external: false,
        url: '/wiki',
      },
    },
    {
      name: 'Use our Calculators',
      icon: calcIcon,
      link: {
        external: false,
        url: '/calculators',
      },
    },
  ];

  render() {
    return (
      <Container id="home">
        <div id="homeIntro">
          <div id="homeIntroFade">
            <div id="homeIntroImage">
              <img
                src="/assets/pics/tbplaceholder.jpg"
                id="homeIntroImageLo"
                alt=""
              />
            </div>
          </div>
        </div>
        {/* 4 Big buttons on top of homescreen */}
        <div id="homeButtons">
          {this.buttonList.map((c) =>
            c.link.external ? (
              <a
                className="linkFix"
                target="_blank"
                rel="noreferrer noopener"
                href={c.link.url}
              >
                <Card className="homeButton">
                  <CardActionArea className="homeButtonActionArea">
                    <Typography
                      className="homeButtonTitle"
                      variant="h6"
                      component="h3"
                    >
                      {c.name}
                    </Typography>
                    {typeof c.icon === 'string' ? (
                      <img
                        className="homeButtonImageIcon"
                        src={c.icon}
                        alt="icon"
                      />
                    ) : (
                      <c.icon className="homeButtonIcon" />
                    )}
                  </CardActionArea>
                </Card>
              </a>
            ) : (
              <Link className="linkFix" to={c.link.url}>
                <Card className="homeButton">
                  <CardActionArea className="homeButtonActionArea">
                    <Typography
                      className="homeButtonTitle"
                      variant="h6"
                      component="h3"
                    >
                      {c.name}
                    </Typography>
                    {typeof c.icon === 'string' ? (
                      <img
                        className="homeButtonImageIcon"
                        src={c.icon}
                        alt="icon"
                      />
                    ) : (
                      <c.icon className="homeButtonIcon" />
                    )}
                  </CardActionArea>
                </Card>
              </Link>
            )
          )}
        </div>
        {/* Quick TDEE calc on homescreen */}
        <div>
          <div id="homeQuickCalc">
            <QuickCalculator />
          </div>
          <iframe
            title="Discord Widget"
            src="https://discord.com/widget?id=605427418873856105&theme=dark&username=Breed Beeld Televisie#1816"
            id="discordWidget"
            height="500"
            allowtransparency="true"
            frameborder="0"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          ></iframe>
        </div>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return { title: state.title };
};

const mapDispatchToProps = {
  setTitleName,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
