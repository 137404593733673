import React, { Fragment } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './TableOfContents.scss';

export default class TableOfContentsComponent extends React.Component {
  scrollToRef = (ref) => {
    if (!ref) return;
    window.scrollTo({
      behavior: 'smooth',
      top: ref + window.scrollY,
    });
  };

  returnList(item) {
    if (item.items && item.items[0])
      return (
        <Accordion className='dropdownParent'>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={item.id + '-panel-content'}
            id={item.id + '-panel-header'}
          >
            <Typography>{item.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List className='list'>
              {item.items.map((e, i) => (
                <Fragment key={e.name}>
                  <ListItem
                    button={e.items && e.items[0] ? false : true}
                    className='listItem'
                    onClick={
                      e.items && e.items[0]
                        ? null
                        : () => this.scrollToRef(e.ref())
                    }
                  >
                    {this.returnList(e)}
                  </ListItem>
                  {item.items[i + 1] ? <Divider /> : null}
                </Fragment>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      );

    if (item.ref && item.ref !== '')
      return (
        <>
          <ListItemIcon>
            <LaunchIcon />
          </ListItemIcon>
          <ListItemText primary={item.name} />
        </>
      );
  }

  render() {
    return <>{this.returnList(this.props.items)}</>;
  }
}
