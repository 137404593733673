/* eslint-disable no-extend-native */
export default function nativeExtentsions() {
  String.prototype.parameterize = function () {
    if (!this || typeof this !== 'string') {
      return;
    }
    return this.trim()
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 -]/, '')
      .replace(/\s/g, '-')
      .replace('--', '-');
  };
}
/* eslint-enable no-extend-native */
