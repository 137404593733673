import React, { Fragment } from 'react';
import {
  Typography,
  Card,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import TableOfContentsComponent from '../TableOfContents/TableOfContents';
import QuickCalculator from '../../QuickCalculator/QuickCalculator';
import TableOfContentsMobileComponent from '../TableOfContentsMobile/TableOfContentsMobile';
import WikiPlaceHolder from './WikiPlaceHolder';
import LinkIcon from '@material-ui/icons/Link';
export default class WikiItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clipBoardMessage: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  refNames = {};

  tableOfContentsArray = {
    name: 'Table of contents:',
    items: [],
  };

  componentDidUpdate() {
    this.tableOfContentsArray = {
      name: 'Table of contents:',
      items: [],
    };
  }

  componentDidMount() {
    if (this.props.section) {
      var result = this.tableOfContentsArray.items.filter((obj) => {
        return obj.id === this.props.section;
      })[0];

      if (result) {
        window.scrollTo({
          behavior: 'smooth',
          top: result.ref() + window.scrollY,
        });
      }
    }
  }

  scrollTo(ref) {
    ref.current.scrollIntoView();
  }

  handleClick(url) {
    navigator.clipboard.writeText(url);
    this.setState({ ...this.state, clipBoardMessage: true });
  }

  handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ ...this.state, clipBoardMessage: false });
  }

  getRefHeight(ref) {
    if (!ref || !ref.current) return;
    return ref.current.getBoundingClientRect().top;
  }

  getContent(content) {
    switch (content.type) {
      case 'title':
        var result = this.tableOfContentsArray.items.filter((obj) => {
          return obj.name === content.text;
        })[0];
        if (!result) {
          this.refNames[content.text.replace(/\s/g, '')] = {
            ref: React.createRef(),
          };
          this.tableOfContentsArray.items.push({
            name: content.text,
            id: content.text.parameterize(),
            ref: () =>
              this.getRefHeight(
                this.refNames[content.text.replace(/\s/g, '')].ref
              ),
          });
        }
        return (
          <Typography
            variant='h5'
            className='topicSection'
            ref={this.refNames[content.text.replace(/\s/g, '')].ref}
          >
            {content.text}
            <IconButton
              className='linkIcon'
              onClick={() =>
                this.handleClick(
                  `${window.location.host}/wiki/${
                    this.props.item.id
                  }/${content.text.parameterize()}`
                )
              }
            >
              <LinkIcon />
            </IconButton>
          </Typography>
        );
      case 'article':
        return (
          <Typography variant='body1' gutterBottom>
            {content.text}
          </Typography>
        );
      case 'component':
        switch (content.text) {
          case 'quickTDEECalculator':
            return <QuickCalculator />;

          default:
            return null;
        }
      default:
        return null;
    }
  }

  render() {
    return (
      <>
        <Typography variant='h4' id='wikiItemTitle'>
          {this.props.item
            ? this.props.item.name
            : 'Welcome to the Fitness Wiki!'}
        </Typography>
        <div className='desktopTOC'>
          <Card id='topicSelectorDesktop' className='topicSelector'>
            <List id='topicSelectorList'>
              {this.props.itemList.map((e, i) => (
                <Fragment key={e.id}>
                  <ListItem button onClick={() => this.props.setTopic(i)}>
                    <ListItemText primary={e.name} />
                  </ListItem>
                  {this.props.itemList[i + 1] ? <Divider /> : null}
                </Fragment>
              ))}
            </List>
          </Card>
          {this.props.item && (
            <TableOfContentsComponent items={this.tableOfContentsArray} />
          )}
        </div>
        <div className='mobileTOC'>
          {this.props.item && (
            <TableOfContentsMobileComponent items={this.tableOfContentsArray} />
          )}
        </div>

        {this.props.item !== null ? (
          this.props.item.content.map((e) => {
            return this.getContent(e);
          })
        ) : (
          <WikiPlaceHolder />
        )}
        <Snackbar
          open={this.state.clipBoardMessage}
          autoHideDuration={1500}
          onClose={this.handleClose}
          message='Link copied to clipboard!'
        />
      </>
    );
  }
}
