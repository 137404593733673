import React from 'react';
import { MenuItem, FormControl, TextField } from '@material-ui/core';
import { getHeightMenuValues } from '../../../utils/calculations/calculations';

export default class InputWithUnit extends React.Component {
  constructor(props) {
    super(props);
    ({
      onChange: this.onChange,
      unitType: this.unitType,
      value: this.value,
      ...this.rest
    } = this.props);
  }

  GetUnitType = () => {
    switch (this.unitType) {
      case 'weight':
        return (
          <>
            <TextField
              className="unitSelect"
              labelId={`${'bmi'}-${this.unitType}-unit-label`}
              variant="filled"
              value={this.props.value.unit}
              label="Unit"
              name="unit"
              onChange={this.onChange}
              select
              InputProps={{
                style: { borderTopLeftRadius: 0 },
              }}
            >
              <MenuItem value={'metric'}>Kg</MenuItem>
              <MenuItem value={'imperial'}>Lbs</MenuItem>
            </TextField>
          </>
        );
      case 'height':
        return (
          <>
            <TextField
              className="unitSelect"
              labelId={`${'bmi'}-${this.unitType}-unit-label`}
              variant="filled"
              value={this.props.value.unit}
              label="Unit"
              name="unit"
              onChange={this.onChange}
              select
              InputProps={{
                style: { borderTopLeftRadius: 0 },
              }}
            >
              <MenuItem value={'metric'}>Cm</MenuItem>
              <MenuItem value={'imperial'}>Ft</MenuItem>
            </TextField>
          </>
        );
      default:
        return '';
    }
  };

  render() {
    return (
      <div className="inputWithUnit">
        <FormControl className="measurementInput">
          {this.props.value.unit === 'metric' || this.unitType !== 'height' ? (
            <TextField
              className="measurementInputField"
              variant="filled"
              value={this.props.value[this.props.name]}
              onChange={this.onChange}
              {...this.rest}
              InputProps={{
                style: { borderTopRightRadius: 0 },
              }}
              name={this.props.name}
            />
          ) : (
            <TextField
              className="measurementInputField"
              labelId="imperial-height-label"
              variant="filled"
              value={this.props.value[this.props.name]}
              {...this.rest}
              onChange={this.onChange}
              select
              InputProps={{
                style: { borderTopRightRadius: 0 },
              }}
              name={this.props.name}
            >
              {getHeightMenuValues().map((menuItems) => (
                <MenuItem
                  value={`${menuItems.f}|${menuItems.i}`}
                >{`${menuItems.f}'${menuItems.i}"`}</MenuItem>
              ))}
            </TextField>
          )}
        </FormControl>
        {/* Unit selector */}
        <FormControl className="unit">
          <this.GetUnitType />
        </FormControl>
      </div>
    );
  }
}
