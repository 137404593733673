import {
  Typography,
} from '@material-ui/core';

export default function WikiPlaceHolder() {
  return (
      <Typography variant='subtitle1'>
        Welcome to the fitness wiki. A collection of fitness information made
        and checked by the staff of Team Bicep. Select a category from the list
        to get started!
      </Typography>
  );
}
