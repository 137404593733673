import React from 'react';
import './calc.scss';
import {
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { oneRM } from '../../utils/calculations/calculations';
import DetailsComponent from './DetailButton/DetailsComponent';
import { FullWidthInput } from './inputs/FullWidthInput';
import InputWithUnit from './inputs/InputWithUnit';
import InputRegular from './inputs/InputRegular';

export default class OneRMCalcComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qCalc: {
        weight: '',
        reps: '',
        unit: 'metric',
        result: null,
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    const qCalc = {
      ...this.state.qCalc,
      [event.target.name]: event.target.value,
    };
    if (qCalc.weight && qCalc.reps && qCalc.unit) {
      qCalc.result = oneRM(qCalc);
    } else {
      qCalc.result = null;
    }
    this.setState({
      ...this.state,
      qCalc: qCalc,
    });
  };

  render() {
    return (
      <>
        <FullWidthInput>
          <InputWithUnit
            onChange={this.handleChange}
            unitType="weight"
            label="Weight"
            name="weight"
            type="number"
            value={this.state.qCalc}
          />
        </FullWidthInput>

        <InputRegular
          onChange={this.handleChange}
          unitType="reps"
          label="Reps"
          name="reps"
          type="number"
          value={this.state.qCalc}
        />

        <div id="result">
          <Typography variant="h6">
            {this.state.qCalc.result
              ? 'Your 1 rep max is: '
              : 'Enter your details above to see your one rep max'}
          </Typography>
          <Typography color="primary" variant="h5">
            {this.state.qCalc.result ? Math.round(this.state.qCalc.result) : ''}
          </Typography>
        </div>
        {this.state.qCalc.result !== 0 && this.state.qCalc.result && (
          <DetailsComponent className="detailsAccordion">
            <TableContainer className="tdeeTable">
              <Table className="tableElement">
                <TableHead>
                  <TableRow>
                    <TableCell className="cell">Repetitions</TableCell>
                    <TableCell className="cell">Weight</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[
                    1, 0.97, 0.94, 0.92, 0.89, 0.86, 0.83, 0.81, 0.78, 0.75,
                  ].map((e, s) => (
                    <TableRow>
                      <TableCell className="cell">{s + 1} Reps</TableCell>
                      <TableCell className="cell">
                        {Math.round(this.state.qCalc.result * e)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DetailsComponent>
        )}
      </>
    );
  }
}
