import React from 'react';
import { connect } from 'react-redux';
import { setTitleName } from '../../actions/setTitle';
import itemList from '../../Components/WikiComponents/WikiContent/items';
import WikiItem from '../../Components/WikiComponents/WikiContent/WikiItem';
import {
  Container,
  Typography,
} from '@material-ui/core';
import './Wiki.scss';
import TopicSelectorMobile from '../../Components/WikiComponents/TopicSelectorMobile/TopicSelectorMobile';
class Wiki extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.match.params.topic) {
      const id = this.props.match.params.topic;
      for (let i = 0; i <= itemList.length; i++) {
        if (itemList[i] && itemList[i].id === id) {
          this.state = { topic: i, panel: false };
          return;
        } else if (i >= itemList.length) {
          this.state = { topic: null, panel: true };
          return;
        }
      }
    } else {
      this.state = { topic: null, panel: true };
    }
  }
  //Set banner title
  componentDidMount() {
    this.props.setTitleName('Wiki');
  }
  //returns html as component if theres no selected article
  GetPlaceHolderHtml() {
    return (
      <>
        <Typography variant='h2' component='h2'>
          Welcome to the Fitness Wiki!
        </Typography>
        <Typography variant='subtitle1'>
          Welcome to the fitness wiki. A collection of fitness information made
          and checked by the staff of Team Bicep. Select a category from the
          list to get started!
        </Typography>
      </>
    );
  }
  //Enable or disable the dropdown panel
  handlePanel = () => {
    this.setState({ ...this.state, panel: !this.state.panel });
  };
  //Load a topic doc
  setTopic = (id) => {
    //return if no ID
    if (id === null || id === undefined) return;
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    this.setState({
      ...this.state,
      panel: false,
      topic: id,
    });
  };

  render() {
    return (
      <Container id='wiki'>
        <TopicSelectorMobile
          panel={this.state.panel}
          handlePanel={this.handlePanel}
          setTopic={this.setTopic}
          itemList={itemList}
        />
        <div id='wikiContent'>
          <WikiItem
            section={this.props.match.params.section || null}
            itemList={itemList}
            setTopic={this.setTopic}
            item={itemList[this.state.topic] || null}
          />
        </div>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return { title: state.title };
};

const mapDispatchToProps = {
  setTitleName,
};

export default connect(mapStateToProps, mapDispatchToProps)(Wiki);
