import React from 'react';
import './Banner.scss';
import { connect } from 'react-redux';
import { setTitleName } from '../../actions/setTitle';
import {
  Slide,
  Container,
  Button,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import MenuIcon from '@material-ui/icons/Menu';

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

class BannerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.state = { drawer: false };
  }

  toggleDrawer(mode) {
    if (!mode) mode = !this.state.drawer;
    this.setState({ ...this.state, drawer: mode });
  }
  getMenuItems() {
    return [
      {
        name: 'Home',
        link: '/',
      },
      {
        name: 'The Fitness Wiki',
        link: '/wiki',
      },
      {
        name: 'Our Calculators',
        link: '/calculators',
      },
      {
        name: 'Team Bicep Trading',
        disabled: true,
      },
    ];
  }
  redirect(link) {
    this.props.history.push(link);
  }

  render() {
    return (
      <>
        <div id="bannerStaticPlaceholder">
          <HideOnScroll {...this.props}>
            <AppBar id="bannerPaper">
              <Container id="bannerContainer">
                <Toolbar>
                  <Link to="/">
                    <img
                      id="bannerLogo"
                      src="/assets/logoWhite.svg"
                      alt="Team Bicep Logo"
                    />
                  </Link>
                  <IconButton
                    id="mobileMenuIconButton"
                    onClick={this.toggleDrawer}
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Drawer
                    anchor={'left'}
                    open={this.state.drawer}
                    onClose={() => this.toggleDrawer(false)}
                  >
                    <List>
                      {this.getMenuItems().map((item) =>
                        item.disabled ? (
                          <ListItem disabled>
                            <ListItemText primary={item.name} />
                          </ListItem>
                        ) : (
                          <Link
                            style={{ textDecoration: 'none', color: 'white' }}
                            className="linkFix"
                            to={item.disabled ? '' : item.link}
                            key={item.link}
                          >
                            <ListItem
                              onClick={() => this.toggleDrawer(false)}
                              button
                            >
                              <ListItemText primary={item.name} />
                            </ListItem>
                          </Link>
                        )
                      )}
                    </List>
                  </Drawer>
                  <Typography variant="h5">{this.props.title}</Typography>
                  <div id="bannerDesktopButtons">
                    {this.getMenuItems().map((item) =>
                      item.disabled ? (
                        <Button disabled>{item.name}</Button>
                      ) : (
                        <Link className="linkFix" to={item.link}>
                          <Button className="menuItem">{item.name}</Button>
                        </Link>
                      )
                    )}
                  </div>
                </Toolbar>
              </Container>
            </AppBar>
          </HideOnScroll>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { title: state.title };
};

const mapDispatchToProps = {
  setTitleName,
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerComponent);
