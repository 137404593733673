import React from 'react';
import './calc.scss';
import {
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@material-ui/core';
import {
  tdee,
} from '../../utils/calculations/calculations';
import DetailsComponent from './DetailButton/DetailsComponent';
import { Link } from 'react-router-dom';
import { HalveWidthInput } from './inputs/HalveWidthInput';
import InputRegular from './inputs/InputRegular';
import InputWithUnit from './inputs/InputWithUnit';

export default class TDEECalcComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qCalc: {
        gender: '',
        age: '',
        metricWeight: '',
        metricHeight: '',
        imperialWeight: '',
        imperialHeight: '',
        activityLevel: 1,
        unit: 'metric',
        result: null,
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    const qCalc = {
      ...this.state.qCalc,
      [event.target.name]: event.target.value,
    };
    if (event.target.name === 'unit') {
      qCalc.metricWeight = '';
      qCalc.imperialWeight = '';
      qCalc.metricHeight = '';
      qCalc.imperialHeight = '';
    }
    if (qCalc.gender && qCalc.age && qCalc.unit) {
      if (qCalc.unit === 'metric' && qCalc.metricWeight && qCalc.metricHeight) {
        qCalc.result = tdee(qCalc);
      } else if (
        qCalc.unit === 'imperial' &&
        qCalc.imperialWeight &&
        qCalc.imperialHeight
      ) {
        qCalc.result = tdee(qCalc);
      } else {
        qCalc.result = null;
      }
    } else {
      qCalc.result = null;
    }
    this.setState({
      ...this.state,
      qCalc: qCalc,
    });
  };

  render() {
    return (
      <>
        <HalveWidthInput>
          <InputRegular
            onChange={this.handleChange}
            unitType="gender"
            label="Gender"
            name="gender"
            select={[
              { key: 'male', label: 'Male' },
              { key: 'female', label: 'Female' },
            ]}
            value={this.state.qCalc}
          />
          <InputRegular
            onChange={this.handleChange}
            unitType="age"
            label="Age"
            name="age"
            type="number"
            value={this.state.qCalc}
          />
          <InputWithUnit
            onChange={this.handleChange}
            unitType="weight"
            label="Weight"
            name={this.state.qCalc.unit + 'Weight'}
            type="number"
            value={this.state.qCalc}
          />
          <InputWithUnit
            onChange={this.handleChange}
            unitType="height"
            label="Height"
            name={this.state.qCalc.unit + 'Height'}
            type="number"
            value={this.state.qCalc}
          />
        </HalveWidthInput>
        <InputRegular
          onChange={this.handleChange}
          unitType="activityLevel"
          label="Activity level"
          name="activityLevel"
          select={[
            { key: 6, label: 'BMR (No extra calories)' },
            { key: 1, label: 'Sedentary' },
            { key: 2, label: 'Lightly active' },
            { key: 3, label: 'Moderately active' },
            { key: 4, label: 'Very active' },
            { key: 5, label: 'Extra active' },
          ]}
          value={this.state.qCalc}
        />
        <div id="result">
          <Typography variant="h6">
            {this.state.qCalc.result
              ? 'Your TDEE is:'
              : 'Fill in your details above to see your TDEE'}
          </Typography>
          <Typography color="primary" variant="h5">
            {this.state.qCalc.result !== 0 &&
              this.state.qCalc.result &&
              Math.round(this.state.qCalc.result) + ' Calories'}
          </Typography>
        </div>
        {this.state.qCalc.result !== 0 && this.state.qCalc.result && (
          <DetailsComponent className="detailsAccordion">
            <TableContainer className="tdeeTable">
              <Table className="tableElement">
                <TableHead>
                  <TableRow>
                    <TableCell className="cell">Weight loss</TableCell>
                    <TableCell className="cell">Weight gain</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="cell">
                      {Math.round(this.state.qCalc.result) - 500} calories
                    </TableCell>
                    <TableCell className="cell">
                      {Math.round(this.state.qCalc.result) + 300} calories
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Link className="linkFix" to="/wiki">
                <Button variant="contained" className="wikiButton">
                  Read more in on Wiki page
                </Button>
              </Link>
            </TableContainer>
          </DetailsComponent>
        )}
      </>
    );
  }
}
