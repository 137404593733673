/**
 * @module Calculations
 **/

const toMetric = (value, input) => {
  switch (input) {
    case 'weight':
      return value / 2.2046226218;
    case 'inch':
      return value / 0.3937;
    case 'feet':
      return value / 0.032808;
    default:
      break;
  }
};
const toImperial = (value, input) => {
  switch (input) {
    case 'weight':
      return value * 2.2046226218;
    case 'inch':
      return value * 0.3937;
    case 'feet':
      return value * 0.032808;
    default:
      break;
  }
};
export function getHeightMenuValues() {
  let returnValue = [];

  for (let f = 4; f < 8; f++) {
    if (f !== 7) {
      for (let i = 0; i < 12; i++) {
        returnValue.push({ f: f, i: i });
      }
    } else {
      returnValue.push({ f: 7, i: 0 });
    }
  }
  return returnValue;
}

/**
 * Calculate BMR or TDEE. For BMR, put activityLevel on any other number than 1 - 5
 * @param {Object} data - Object with data to calc BMR or TDEE.
 * @param {string} data.gender - gender "male"|"female"
 * @param {Number} data.age - age
 * @param {String} data.unit - Measured unit. "metric" | "imperial"
 * @param {Number} data.metricWeight - weight in Kg. Required is unit is metric
 * @param {Number} data.metricHeight - Height in Kg. Required is unit is metric
 * @param {Number} data.imperialWeight - weight in lbs. Required is unit is imperial
 * @param {Number} data.imperialHeight - Height in lbs. Required is unit is imperial
 * @param {Number} data.activityLevel - level of activity multiplier. 1 - 5 available. otherwise default to BMR
 * @returns {Number} - Number of calories according to your input
 * @example
 * const currentAmountOfCalories = tdee(data)
 * //or
 * const currentTDEE = tdee({age:21, ...})
 */
export function tdee(data) {
  if (!data || data === undefined) return new Error('No data object');
  if (!data.gender)
    return new Error('No gender in data object. Check documentation');
  if (!data.age) return new Error('No age in data object. Check documentation');
  if (!data.unit)
    return new Error('No unit in data object. Check documentation');
  if (!data.activityLevel)
    return new Error('No activity level in data object. Check documentation');
  if (data.unit === 'metric' && !data.metricHeight)
    return new Error(
      'The unit has been set to metric, but no metric height was found in data object. Check documentation'
    );
  if (data.unit === 'metric' && !data.metricWeight)
    return new Error(
      'The unit has been set to metric, but no metric weight was found in data object. Check documentation'
    );
  if (data.unit === 'imperial' && !data.imperialHeight)
    return new Error(
      'The unit has been set to imperial, but no imperial height was found in data object. Check documentation'
    );
  if (data.unit === 'imperial' && !data.imperialWeight)
    return new Error(
      'The unit has been set to imperial, but no imperial height was found in data object. Check documentation'
    );

  if (data.unit === 'imperial') {
    // data.metricWeight = data.imperialWeight - 2.2046;
    // data.metricHeight =
    //   data.imperialHeight.split('|')[0] / 0.032808 +
    //   data.imperialHeight.split('|')[1] / 0.3937;
    data.metricWeight = toMetric(data.imperialWeight, 'weight');
    data.metricHeight =
      toMetric(data.imperialHeight.split('|')[0], 'feet') +
      toMetric(data.imperialHeight.split('|')[1], 'inch');
  }
  let bmrMultiplier = 1;
  switch (data.activityLevel) {
    case 1:
      bmrMultiplier = 1.2;
      break;
    case 2:
      bmrMultiplier = 1.375;
      break;
    case 3:
      bmrMultiplier = 1.55;
      break;
    case 4:
      bmrMultiplier = 1.725;
      break;
    case 5:
      bmrMultiplier = 1.9;
      break;
    default:
      bmrMultiplier = 1;
  }
  return (
    (10 * data.metricWeight +
      6.25 * data.metricHeight -
      5.0 * data.age +
      (data.gender === 'male' ? 5 : -161)) *
    bmrMultiplier
  );
}

/**
 * Calculate your 1rm
 * @param {Object} data - Object with data to calculate 1rm.
 * @param {Number} data.weight - The amount of weight thats lifted
 * @param {Number} data.reps - The amount of reps in the workout
 * @param {String} data.unit - Measured unit. "metric" | "imperial"
 * @returns {Number} - Your 1rm weight
 * @example
 * data = {weight: 60, reps, 10, unit: "metric"}
 * const my1RM = oneRM(data) // my1RM = 81
 * //or
 * const my1RM = tdee({weight: 60, reps, 10, unit: "metric"}) // my1RM = 81
 */
export function oneRM(data) {
  if (!data || data === undefined) return new Error('No data object');
  if (!data.weight)
    return new Error('No weight in data object. Check documentation');
  if (!data.reps)
    return new Error('No reps in data object. Check documentation');
  if (!data.unit)
    return new Error('No unit in data object. Check documentation');
  if (data.unit !== 'metric' && data.unit !== 'imperial')
    return new Error('The unit needs to be set to metric or imperial');

  // const weight = typeof data.weight === 'boolean' ? -1 : Number(data.weight);
  // const reps = typeof data.reps === 'boolean' ? -1 : Number(data.reps);
  let weight = Number(data.weight);
  const reps = Number(data.reps);
  if (reps <= 1) return weight;
  const unit = data.unit;
  if (unit === 'imperial') {
    weight = toMetric(weight, 'weight');
  }

  // if (weight > 0 && reps > 0 && reps <= 10) return 'Incorrect weight types';
  const result = Number((weight * (1 + reps / 30)).toFixed(0));
  // const result = Number(
  //   ((100 * weight) / (48.8 + 53.8 * Math.pow(Math.E, -0.075 * reps))).toFixed(
  //     0
  //   )
  // );
  return data.unit === 'metric' ? result : toImperial(result, 'weight');
}

/**
 * Calculate your BMI
 * @param {Object} data - Object with data to calculate BMI.
 * @param {Number} data.weight - The user's weight
 * @param {Number} data.height - The user's height
 * @param {String} data.unit - Measured unit. "metric" | "imperial"
 * @returns {Number} - The user's BMI
 * @example
 * data = {weight: 90, height: 190, unit: "metric"}
 * const myBMI = BMI(data)
 * //or
 * const myBMI = BMI({weight: 90, height: 190, unit: "metric"})
 */
export function BMI(data) {
  if (!data || data === undefined) return new Error('No data object');
  if (!data.weight)
    return new Error('No weight in data object. Check documentation');
  if (!data.height)
    return new Error('No height in data object. Check documentation');
  if (!data.unit)
    return new Error('No unit in data object. Check documentation');
  if (data.unit !== 'metric' && data.unit !== 'imperial')
    return new Error('The unit needs to be set to metric or imperial');

  let weight = data.weight;
  let height = data.height;
  let unit = data.unit;
  if (unit === 'imperial') {
    if (typeof height !== 'string')
      return new Error(
        'Height needs to be set to string as <feet>|<inch> When using imperial units'
      );
    weight = toMetric(weight, 'weight');
    height =
      toMetric(height.split('|')[0], 'feet') +
      toMetric(height.split('|')[1], 'inch');
  }
  //cm to m
  height = height / 100;
  unit = 'metric';
  return Number((weight / (height * height)).toFixed(0));
  // switch (data.unit) {
  //   case "imperial":
  //     return ((data.weight) / (data.height * data.height)).toFixed(0)
  //   case "metric":
  //     return (703 * ((data.weight) / (data.height * data.height))).toFixed(0)
  //   default:
  //     return null
  // }
}
