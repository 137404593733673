import React from 'react';
import './Calculators.scss';
import TDEECalcComponent from '../../Components/Calculators/TDEECalcComponent';
import BMICalcComponent from '../../Components/Calculators/BMICalcComponent';
import OneRMCalcComponent from '../../Components/Calculators/OneRMCalcComponent';
import { connect } from 'react-redux';
import { setTitleName } from '../../actions/setTitle';
import { AppBar, Tab, Tabs, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';

function CalcContent(props) {
  const theme = useTheme();
  return (
    <SwipeableViews
      containerStyle={{
        transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
      }}
      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
      index={props.value}
      {...props}
    >
      {props.children}
    </SwipeableViews>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class Calculators extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.match.params.type) {
      let calcIndex = this.calcItems.findIndex(
        (obj) => obj.id === this.props.match.params.type
      );
      if (calcIndex === -1) calcIndex = 0;
      this.state = { value: calcIndex };
    } else {
      this.state = { value: 0 };
    }
  }
  refNames = {};
  calcItems = [
    {
      id: 'bmi',
      name: 'BMI',
      description: `BMI is an international 
        measuring system to calculate your health 
        using height and length. Though it isn't always accurate`,
      imgPath: '/assets/pics/calcImg1thumbnail.jpg',
      component: BMICalcComponent,
    },
    {
      id: 'tdee',
      name: 'Tdee/BMR',
      description: `Your BMR is the base amount of 
        calories you burn per day. TDEE is your BMR + Activity level`,
      imgPath: '/assets/pics/tbplaceholderthumbnail.jpg',
      component: TDEECalcComponent,
    },
    {
      id: 'onerm',
      name: 'One rep max',
      description: `One rep max is a measurement technique to 
        indicate your approximate lifting weight for 1 repetition`,
      imgPath: '/assets/pics/tbplaceholderthumbnail.jpg',
      component: OneRMCalcComponent,
    },
  ];

  componentDidMount() {
    this.props.setTitleName('Calculators');
  }
  scrollTo(ref) {
    if (!ref || !ref.current) return;
    window.scrollTo({
      behavior: 'smooth',
      top: ref.current.getBoundingClientRect().top + window.scrollY,
    });
  }
  handleChange = (event, value) => {
    this.setState({ ...this.state, value: value });
  };
  handleChangeIndex = (value) => {
    this.setState({ ...this.state, value: value });
  };
  render() {
    return (
      <div id="calcComponent">
        <AppBar position="static" id="calcSelectorBar" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {this.calcItems.map((e, i) => (
              <Tab key={i} label={e.name} {...a11yProps(i)} />
            ))}
          </Tabs>
        </AppBar>

        <CalcContent
          onChangeIndex={this.handleChangeIndex}
          value={this.state.value}
        >
          {this.calcItems.map((e, i) => (
            <TabPanel key={i} value={this.state.value} index={i}>
              {/* <Card> */}
              <Typography className="calculatorName" variant="h4">{e.name} Calculator</Typography>
              <Typography className="calculatorDescription" variant="p">{e.description}</Typography>
              <e.component />
              {/* <e.Component /> */}
              {/* </Card> */}
            </TabPanel>
          ))}
        </CalcContent>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return { title: state.title };
};

const mapDispatchToProps = {
  setTitleName,
};

export default connect(mapStateToProps, mapDispatchToProps)(Calculators);
