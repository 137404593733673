import React from 'react';

import {
  Backdrop,
} from '@material-ui/core';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import LaunchIcon from '@material-ui/icons/Launch';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './TableOfContents.scss';

export default function SpeedDials(props) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const scrollToRef = (ref) => {
    handleClose()
    if (!ref) return;
    window.scrollTo({
      behavior: 'smooth',
      top: ref + window.scrollY - 50,
    });
  };
  
  const returnList = (item) => {
    if (item.items && item.items[0])
      return (
        <>
          <Backdrop open={open} />
          <SpeedDial
            ariaLabel='SpeedDial example'
            hidden={false}
            icon={open ? <ExpandMoreIcon/> : <LaunchIcon/>}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={'up'}
          >
            {item.items.slice(0).reverse().map((e) => (
              <SpeedDialAction
                className='mobileTOCItem'
                key={e.name}
                tooltipOpen
                icon={<LaunchIcon />}
                tooltipTitle={e.name}
                onClick={
                  e.items && e.items[0] ? null : () => scrollToRef(e.ref())
                }
              />
            ))}
          </SpeedDial>
        </>
      );
  };

  return returnList(props.items);
}
