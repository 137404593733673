import React from 'react';
import './Footer.scss'
// Components
// import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// import { Link } from 'react-router-dom';

export default class Footer extends React.Component {
//   constructor(props) {
//     super(props);
//     // this.toggleDrawer = this.toggleDrawer.bind(this);
//   }

  render() {
    return (
      <footer id="footer">
          <Typography variant="subtitle2">© 2019-2021 <a target="_blank" href="https://ogafit.com" rel="noreferrer noopener">Oga Fitness.</a> Team Bicep. All Rights Reserved</Typography>
      </footer>
    );
  }
}
